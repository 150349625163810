import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { DocumentNode, FetchPolicy } from '@apollo/client';
import { useRouter } from 'next/router';

import { apollo_client } from '@src/bootstrap';
import { setInitialUserLoaded, setLoading, setUser } from '@src/features/auth';
import { USER } from '@src/services/user';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { User } from '@src/ts/interfaces';
import { handleLogout } from '@src/utils/user';

import { useAppDispatch, useAppSelector } from '../hooks';

const REFRESH_ACCESS_TOKEN_MS = 5 * 60 * 1000; // 5 minutes

export const api_client = {
    async query<T>({
        fetchPolicy,
        query,
        variables,
    }: {
        query: DocumentNode;
        variables?: { [key: string]: unknown };
        fetchPolicy?: FetchPolicy;
    }): Promise<T> {
        const { data } = await apollo_client.query({
            query,
            variables,
            fetchPolicy: fetchPolicy || 'cache-first',
        });
        return data;
    },
    async mutate<T>({
        mutation,
        variables,
    }: {
        mutation: DocumentNode;
        variables?: { [key: string]: unknown };
    }): Promise<T> {
        const { data } = await apollo_client.mutate({
            mutation,
            variables,
        });
        return data;
    },
};

export const useRefreshTokenAuth = (): void => {
    const dispatch = useAppDispatch();
    const { loading, initial_user_loaded } = useAppSelector(
        (state) => state.auth,
    );
    const router = useRouter();

    const refreshAccessToken = async (): Promise<void> => {
        const refresh_token = localStorage.getItem('decubate_refresh_token');

        if (!refresh_token) {
            if (loading) dispatch(setLoading(false));
            return;
        }

        try {
            const { refreshAccessToken } = await api_client.mutate<{
                refreshAccessToken: { access_token: string };
            }>({
                mutation: USER.REFRESH_ACCESS_TOKEN,
                variables: { refresh_token },
            });

            if (refreshAccessToken?.access_token) {
                localStorage.setItem(
                    'decubate_access_token',
                    refreshAccessToken.access_token,
                );
            }
        } catch (err) {
            /* empty */
        }

        try {
            const { retrieveMe: user } = await api_client.query<{
                retrieveMe: User;
            }>({
                query: USER.RETRIEVE_ME,
                fetchPolicy: 'network-only',
            });
            // redux
            dispatch(setUser(user));
            if (!initial_user_loaded) {
                if (
                    user.identity_verified === IdentityVerifiedStatus.UNVERIFIED
                )
                    await router.push('/verify/identity');

                dispatch(setInitialUserLoaded(true));
            }
        } catch (err) {
            if (err.message?.includes('400')) {
                handleLogout(router);
            } else if (err.message !== 'Unauthorized access') {
                toast.error(err.message);
            }
        }

        if (loading) {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        refreshAccessToken();
        const handler = setInterval(
            refreshAccessToken.bind(this),
            REFRESH_ACCESS_TOKEN_MS,
        );
        return () => clearInterval(handler);
    });
};
